<template>
    <div class="pdf-page" v-wechat-title="`${$route.meta.title}_京蒙教育协作智慧平台`">
        <div class="manage-wrapper">
            <div class="download">
                <button class="download-btn csp" @click="downloadPDF"><i class="el-icon-download"></i>下载</button>
            </div>
            <div class="pdf-wrapper" v-loading="loading" element-loading-text="正在加载pdf...">
                <pdf :src="src" v-for="i in totalPage" :key="i" :page="i"></pdf>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    import pdf from 'vue-pdf'

    export default {
        components: {
            pdf
        },
        computed: {
            ...mapState({
                fileToken: state => state.user.fileToken,
                pdfUrl: state => state.url.pdfUrl,
                pdfName: state => state.url.pdfName,
                currentProject: state => state.user.currentProject,
            })
        },
        data() {
            return {
                totalPage: undefined,
                src: undefined,
                loading: true,
            }
        },
        methods: {
            loadingTask() {
                // this.src = pdf.createLoadingTask(this.downloadURL + this.$route.params.md5)
                this.src = pdf.createLoadingTask(this.downloadURL + this.pdfUrl)
                this.src.promise.then(pdf => {
                    this.loading = false;
                    this.totalPage = pdf.numPages;
                })
            },
            downloadPDF() {
                window.open(
                    `${this.downloadURL}${this.pdfUrl}&token=${this.fileToken}&type=2`
                    // `${this.downloadURL}${this.$route.params.md5}&token=${essionStorage.getItem('fileToken')}&type=2`
                )
            },
        },
        mounted() {
            this.loadingTask();
            this.$nextTick(() => {
                this.$route.meta.title = this.pdfName;
                this.$store.commit('breadPageNameChange', this.pdfName)
            })
        },
        destroyed() {
            this.$store.commit('getPdfUrl', '')
            this.$store.commit('getPdfName', '')
        }
    }
</script>

<style lang="less" scoped>
    .download {
        text-align: right;
        padding-right: 10px;
    }

    .download-btn {
        display: inline-block;
        color: #999;
        margin-bottom: 15px;

        .el-icon-download {
            margin-right: 5px;
        }
    }

    .pdf-wrapper {
        height: calc(100vh - 200px);
        overflow-y: auto;

        /*修改滚动条样式*/
        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 2px;
        }

        &::-webkit-scrollbar-button {
            height: 0px;
        }

        &::-webkit-scrollbar-thumb {
            background: #bfbfbf;
            border-radius: 4px;
        }


    }
</style>